@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --mainColor: #fff;
}

.mainColor {
  background-color: var(--mainColor);
}

/* footer box liner gradient  */
.footer_bottom_box {
  background: rgb(24, 24, 24);
  background: -moz-linear-gradient(
    90deg,
    rgba(24, 24, 24, 1) 0%,
    rgba(101, 13, 10, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(24, 24, 24, 1) 0%,
    rgba(101, 13, 10, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(24, 24, 24, 1) 0%,
    rgba(101, 13, 10, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#181818",endColorstr="#650d0a",GradientType=1);
}

/* header liner gradient */
.header {
  background: rgb(7, 7, 7);
  background: -moz-linear-gradient(
    0deg,
    rgba(7, 7, 7, 0) 0%,
    rgba(24, 24, 24, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(7, 7, 7, 0) 0%,
    rgba(24, 24, 24, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(28, 28, 28, 0) 0%,
    rgba(14, 14, 14, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#070707",endColorstr="#181818",GradientType=1);
}

/* menu box css  */
.menu-box {
  border-width: 0.5px;
  border-style: solid;
  border-image: linear-gradient(to right, #ffffff18, #ffffff31) 1;
}

/* brand scroll logo transition css */
.marquee {
  display: flex;
  block-size: var(--marquee-item-height);

  position: relative;
  overflow-x: hidden;

  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
}

.marquee--8 {
  --marquee-item-width: 100px;
  --marquee-item-height: 100px;
  --marquee-duration: 20s;
  --marquee-items: 7;
}

.marquee__item {
  --marquee-item-offset: max(
    calc(var(--marquee-item-width) * var(--marquee-items)),
    calc(110% + var(--marquee-item-width))
  );
  --marquee-delay: calc(
    var(--marquee-duration) / var(--marquee-items) *
      (var(--marquee-items) - var(--marquee-item-index)) * -1
  );
  position: absolute;
  inset-inline-start: var(--marquee-item-offset);
  transform: translatex(-20%);
  animation: go linear var(--marquee-duration) var(--marquee-delay, 0s) infinite;
}

@keyframes go {
  0%,
  100% {
    transform: translatex(-20%);
  }
  50% {
    transform: translatex(calc(-20% - var(--marquee-item-offset)));
  }
}

.marquee__item:nth-of-type(1) {
  --marquee-item-index: 1;
}

.marquee__item:nth-of-type(2) {
  --marquee-item-index: 2;
}

.marquee__item:nth-of-type(3) {
  --marquee-item-index: 3;
}

.marquee__item:nth-of-type(4) {
  --marquee-item-index: 4;
}

.marquee__item:nth-of-type(5) {
  --marquee-item-index: 5;
}

.marquee__item:nth-of-type(6) {
  --marquee-item-index: 6;
}

.marquee__item:nth-of-type(7) {
  --marquee-item-index: 7;
}

@keyframes go {
  to {
    inset-inline-start: calc(var(--marquee-item-width) * -1);
  }
}

@media (min-width: 510px) and (max-width: 1280px) {
  .marquee--8 {
    --marquee-item-width: 60px;
    --marquee-duration: 25s;
  }

  .marquee__item {
    --marquee-item-offset: max(
      calc(var(--marquee-item-width) * var(--marquee-items)),
      calc(180% + var(--marquee-item-width))
    );
    --marquee-delay: calc(
      var(--marquee-duration) / var(--marquee-items) *
        (var(--marquee-items) - var(--marquee-item-index)) * -1
    );
    position: absolute;
    inset-inline-start: var(--marquee-item-offset);
    transform: translatex(-20%);
    animation: go linear var(--marquee-duration) var(--marquee-delay, 0s)
      infinite;
  }
}

@media (max-width: 509px) {
  .marquee--8 {
    --marquee-item-width: 120px;
    --marquee-duration: 15s;
  }

  .marquee__item {
    --marquee-item-offset: max(
      calc(var(--marquee-item-width) * var(--marquee-items)),
      calc(190% + var(--marquee-item-width))
    );
    --marquee-delay: calc(
      var(--marquee-duration) / var(--marquee-items) *
        (var(--marquee-items) - var(--marquee-item-index)) * -1
    );
    position: absolute;
    inset-inline-start: var(--marquee-item-offset);
    transform: translatex(-20%);
    animation: go linear var(--marquee-duration) var(--marquee-delay, 0s)
      infinite;
  }
}

/* brand scroll logo transition css end */

/* large button transition css */

.menu {
  display: flex;
  justify-content: center;
}

.menu a span:first-child {
  display: inline-block;
  padding: 8px;
}

.menu a {
  display: block;
  position: relative;
  overflow: hidden;
}
.menu a span:last-child {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-100%);
}

.menu a span {
  transition: transform 0.2s ease-out;
}
.menu a:hover span:first-child {
  transform: translateY(100%);
}
.menu a:hover span:last-child {
  transform: none;
}

.menu[data-animation="to-top"] a span:last-child {
  transform: translateY(100%);
}
.menu[data-animation="to-top"] a:hover span:first-child {
  transform: translateY(-100%);
}
.menu[data-animation="to-right"] a span:last-child {
  transform: translateX(-100%);
}
.menu[data-animation="to-right"] a:hover span:first-child {
  transform: translateX(100%);
}
.menu[data-animation="to-left"] a span:last-child {
  transform: translateX(100%);
}
.menu[data-animation="to-left"] a:hover span:first-child {
  transform: translateX(-100%);
}

.large-button:hover .large.button ul.menu li a span {
  background-color: #414040;
}


.contact-component {
  position: relative;
}

.contact-component:hover .menu a span:first-child {
  transform: translateY(100%);
  
}

.contact-component:hover .menu a span:last-child {
  transform: translateY(0);
}
/* large button transition css end */

/* liner gradient membership poster */

.hero-banner{

  background-image: 
    url(../src/Assets/Images/poster1.png);
  background-size: cover;
  background-position: center;
}
@media (max-width: 835px) {
.hero-banner{

  background-image: 
    url(../src/Assets/Images/poster2.png);
  background-size: cover;
  background-position: center;
}
}
@media (max-width: 600px) {
.hero-banner{

  background-image: 
    url(../src/Assets/Images/poster3.png);
  background-size: cover;
  background-position: center;
}
}
.get-membership-poster {
  background-image: linear-gradient(
      -270deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.4)
    ),
    url(../src/Assets/Images/get_membership_bg.png);
  background-size: cover;
  background-position: center;
}

@media (max-width: 763px) {
  .get-membership-poster {
    background-image: linear-gradient(
        360deg,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.401)
      ),
      url(../src/Assets/Images/get_membership_bg.png);
    background-size: cover;
    background-position: center;
  }
}

.contact-form-background {
  background: rgb(239, 239, 239);
  background: -moz-linear-gradient(
    170deg,
    rgba(239, 239, 239, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    170deg,
    rgba(239, 239, 239, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    170deg,
    rgba(239, 239, 239, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#efefef",endColorstr="#ffffff",GradientType=1);
}

/* contact country input css */

.PhoneInputInput .option {
  height: 2.2rem !important ;
}

.PhoneInputInput {
  padding-top: 4px;
  padding-bottom: 4px;
  outline: none !important;
  font-variant-numeric: lining-nums;
  background-color: #ffffff;
  height: 100% !important;
  color: #4d4d4d;
  border: #bd9842;
}

.PhoneInputInput:hover {
  background-color: #f6f6f6 !important;
}

/* contact selected option */

.options {
  height: 55px !important ;
}

.css-w9q2zk-Input2 {
  visibility: visible;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1/1/2/3;
  grid-template-columns: 0 min-content;
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
}

.css-13cymwt-control {
  background-color: #ffffff !important ;
  border: none !important ;
  width: 100% !important ;
  height: 55px !important ;
  display: flex !important ;
  border-radius: 12px !important ;
}

.css-13cymwt-control:hover {
  background-color: #f6f6f6 !important ;
}

.css-13cymwt-control:focus {
  background-color: #f6f6f6 !important ;
}

.css-t3ipsp-control {
  border: solid 1px #0000000a !important ;

  width: 100% !important;
  padding: 0 !important;
  background-color: #f6f6f6 !important ;
  height: 55px !important ;
  border-radius: 4px !important;
}

.css-t3ipsp-control {
  box-shadow: none !important;
  border-radius: 12px !important;
}

.css-mohuvp-dummyInput-DummyInput {
  height: 55px;
}

.css-1dimb5e-singleValue-input {
  height: 55px;
}

.css-art2ul-ValueContainer2 {
  height: 55px;
}

.css-1nmdiq5-menu {
  height: 310px;
  background-color: #f5f5f4 !important;
}

.list {
  background: #000;
}

.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  /* background-color: #000; */
}

.react-seleect-listbox {
  background-color: #9c771f !important;
}

.css-d7l1ni-option {
  padding: 22px !important;
  background-color: #f6f6f6 !important;
  color: #4d4d4d !important;
  font-size: 14px !important;
  border-bottom: solid 1px #00000008;
}

.css-d7l1ni-option :focus {
  padding: 22px !important;

  background-color: #ffffff !important;
  border-bottom: solid 1px #d7b65d;
}

.css-10wo9uf-option :active {
  padding: 22px !important;
  background-color: #f5f5f4 !important;
  border-bottom: solid 1px #e4e4e4;
}

.css-10wo9uf-option {
  padding: 22px !important;
  color: #4d4d4d !important;
  font-size: 14px !important;
  background-color: #ffffff !important;
  border-bottom: solid 1px #e4e4e4;
}

.css-tr4s17-option {
  padding: 22px !important;
  color: #4d4d4d !important;
  font-size: 14px !important;
  background-color: #f6f6f6 !important;
  border-bottom: solid 1px #00000008;
}

.css-1jqq78o-placeholder {
  color: #939191 !important;
  font-size: 14px !important;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.css-tj5bde-Svg {
  fill: #000000 !important;
}

.css-1dimb5e-singleValue {
  color: #4d4d4d !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

/* loader function */
.loader {
  width: 40px;
  height: 40px;
  margin-left: 150px;
  border: 5px solid #fff;

  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* faq components */
.answer {
  max-height: 0;
  overflow: hidden;
}

/* Styling for showing the answer */
.answer.show {
  max-height: 1000px;
  transition: max-height 0.9s ease-in-out;
}

.active {
  background-color: #a50808;
  color: white;
}

.faq-filter {
  overflow: hidden;
}

@media (max-width: 509px) {
  .faq-filter {
    overflow: scroll;
    width: 100%;
  }
  .faq-filter::-webkit-scrollbar {
    display: none;
  }
}

.about-filter {
  overflow: hidden;
}

@media (max-width: 509px) {
  .about-filter {
    overflow: scroll;
  }

  .about-filter::-webkit-scrollbar {
    display: none;
  }
}

.icon {
  width: 60px;
  transition: transform 0.5s ease;
}

.rotate {
  transform: rotate(-90deg);
  transition: transform 0.5s ease;
}

.active-filter {
  background: #a50808;
}

@media (min-width: 509px) {
  .about-heading {
    font-size: 58px;
  }

  .about-description {
    font-size: 28px;
  }
}

.committee-card {
  overflow: hidden;
}

.card-background {
  width: 100%;
  height: 100%;
  transition: filter 0.3s ease;
}

.committee-image {
  position: relative;
  z-index: 1;
  transition: filter 0.3s ease;
  filter: saturate(0);
}

@media (max-width: 509px) {
  .committee-image {
    position: relative;
    z-index: 1;
    transition: filter 0.3s ease;
    filter: saturate(1);
  }
}
.committee-card:hover .card-background {
  filter: grayscale(100%);
}

.committee-card:hover .committee-image {
  filter: saturate(1);
}

.swiper-pagination-bullet {
  background: var(
    --swiper-pagination-bullet-inactive-color,
    #d9d9d9
  ) !important;
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 1) !important;
  height: 8px !important;
  width: 8px !important;
}

.swiper-pagination-bullet-active {
  background: #9e120e !important ;
  height: 8px !important;
  width: 24px !important;
  border-radius: 4px !important ;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  display: none !important;
}

.committee-box {
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.241421568627451) 0%,
    rgba(27, 27, 27, 1) 100%
  );
  background: -webkit-linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.241421568627451) 0%,
    rgba(27, 27, 27, 1) 100%
  );
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.241421568627451) 0%,
    rgba(27, 27, 27, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#1b1b1b",GradientType=1);
}

@media (max-width: 380px) {
  .trophy-icon {
    display: none;
  }
}

.Swiper {
  height: 700px;
}

@media (max-width: 660px) {
  .Swiper {
    height: 600px;
  }
}

@media (max-width: 450px) {
  .Swiper {
    height: 720px;
  }
}

@media (max-width: 750px) {
  .gallery-item {
    grid-column: span 3;
  }
}

.highlights-scroll::-webkit-scrollbar {
  display: none;
}

.match-card::-webkit-scrollbar {
  display: none;
}

.swiper-slide .swiper-slide-active {
  width: 255px;
}

.swiper-wrapper {
  width: 100%;
  height: max-content !important;
  padding-bottom: 64px !important;
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  position: relative;
}
.swiper-pagination-bullet {
  background: #4f46e5;
}

.player_carosel_card {
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 9) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 8) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

.playercard {
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 9) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 8) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 8) 60%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

.playercard:hover {
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.499124649859944) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 60%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

@media (max-width: 600px) {
  .sportsplayers {
    display: none;
  }
}
@media (min-width: 600px) {
  .playercarosel {
    display: none;
  }
}

/*----- card ---- hover ------ */

.card .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.card .slide {
  transition: 0.3s;
}

.card .slide.slide1 {
  display: flex;
  z-index: 1;
  transition: 0.7s;

  /* transform: translateY(100px); */
}
.card:hover .slide.slide1 {
  transform: translateY(0px);
  height: 300px;
}

.card .slide.slide2 {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  transition: 0.6s;
  transform: translateY(40px);
}
.card:hover .slide.slide2 {
  transform: translateY(100);
}

@media (min-width: 600px) {
  .card:hover .slide.slide2 {
    transform: translateY(0);
  }
}

.card .slide.slide2::after {
  content: "";
  position: absolute;
  transform: translateX(-50%);
}

.card .slide.slide2 .content p {
  text-align: center;
  color: #414141;
}

/* ---- hover ------  card ------- */

.box {
  transition: box-shadow 0.3s;
}
.box:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.shop-contact {
  background: linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 2px)
      calc(100% - 2px) no-repeat,
    linear-gradient(90deg, #c7000056 0%, #4b4b4b58 100%);
  
  box-sizing: border-box;
}

@media (max-width: 600px) {
.tap-mobile-menu{
  display: flex;
}
}


.react-loading-skeleton {
  background-color: #E6E6E6 !important;
  --base-color: #E6E6E6 !important;
  --highlight-color: #e6e1e1 !important;
}


.swiper-wrapper{
  display: flex;
  /* gap: 10px; */
}


.share-button {
  transition: background-color 0.3s ease-in-out;
}


.social-share {
 
  opacity: 1;
 
}


@keyframes slideIn {
  from {
    opacity: 1;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}


@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(-10px);
  }
}


.social-share.visible {
  animation: slideIn 0.3s ease forwards; /* Use slideIn animation when becoming visible */
}

.social-share.hidden {
  animation: slideOut 0.3s ease forwards;
  opacity: 1;
}




.blob {
	background: black;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	
	height: 10px;
	width: 10px;
	transform: scale(1);
	animation: pulse-black 2s infinite;
}


.blob.red {
	background: #9E120E;
	box-shadow: 0 0 0 0 rgb(247, 19, 19);
	animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}


.scrollbar::-webkit-scrollbar {
  display: none;
}


.view {
  background-image: url(../src/Assets/Images/view_banner.png);
}

@media only screen and (max-width: 600px) {
  .view {
    background-image: url(../src/Assets/Images/view_mobile.png);
  }
}